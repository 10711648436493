(<template>
  <div class="assignment-btns">
    <router-link v-for="(button, index) in buttons"
                 :key="index"
                 :to="button.action"
                 class="assignment-btns__item assignment-button">
      <div class="assignment-button__image"
           :class="button.iconClass"></div>
      <span class="assignment-button__text">{{ button.text }}</span>
      <div class="assignment-button__arrow-icon"></div>
    </router-link>
  </div>
</template>)

<script>
  export default {
    data() {
      return {
        employeeProgressActive: false
      };
    },
    computed: {
      buttons() {
        return [
          {
            text: this.$gettext('Book an interpreter'),
            type: 'router-link',
            iconClass: 'book-interpreter-icon',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            text: this.$gettext('Order translation'),
            type: 'router-link',
            iconClass: 'order-translation-icon',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'})
          },
          {
            text: this.$gettext('Book sign language translator'),
            type: 'router-link',
            iconClass: 'book-sing-lang-translator',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          },
          {
            text: this.$gettext('Other services'),
            type: 'router-link',
            iconClass: 'other-service',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'})
          }
        ];
      }
    }
  };
</script>

<style scoped>
  .assignment-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .assignment-btns__item {
    display: flex;
    align-items: center;
    width: 24%;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
    cursor: pointer;
  }

  .assignment-btns__item:hover {
    background-color: #fff0eb;
    transition: ease-in-out 0.125s;
  }

  .assignment-btns__item:hover .assignment-button__arrow-icon {
    margin-right: 10px;
  }

  .assignment-button__image {
    width: 48px;
    height: 48px;
    background-repeat: no-repeat;
  }

  .book-interpreter-icon {
    background-image: url(~@assets/imgs/salita/dashboard_icons/assignment_order_icon.svg);
  }

  .order-translation-icon {
    background-image: url(~@assets/imgs/salita/dashboard_icons/order_translation_icon.svg);
  }

  .book-sing-lang-translator {
    background-image: url(~@assets/imgs/salita/dashboard_icons/book_sign_lang_icon.svg);
  }

  .other-service {
    background-image: url(~@assets/imgs/salita/dashboard_icons/other_services_icon.svg);
  }

  .assignment-button__text {
    padding: 0 16px 0 16px;
    font-weight: 400;
    font-size: 14px;
  }

  .assignment-button__arrow-icon {
    width: 16px;
    height: 16px;
    margin-left: auto;
    background-image: url(~@assets/imgs/salita/dashboard_icons/arrow_to_right_grey.svg);
    background-repeat: no-repeat;
  }
</style>
