// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/dashboard_icons/assignment_order_icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/salita/dashboard_icons/order_translation_icon.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/salita/dashboard_icons/book_sign_lang_icon.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/salita/dashboard_icons/other_services_icon.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("@assets/imgs/salita/dashboard_icons/arrow_to_right_grey.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "\n.assignment-btns[data-v-4fa0fb06] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  width: 100%;\n}\n.assignment-btns__item[data-v-4fa0fb06] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 24%;\n  padding: 16px;\n  border-radius: 8px;\n  background-color: #fff;\n  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);\n          box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);\n  cursor: pointer;\n}\n.assignment-btns__item[data-v-4fa0fb06]:hover {\n  background-color: #fff0eb;\n  -webkit-transition: ease-in-out 0.125s;\n  transition: ease-in-out 0.125s;\n}\n.assignment-btns__item:hover .assignment-button__arrow-icon[data-v-4fa0fb06] {\n  margin-right: 10px;\n}\n.assignment-button__image[data-v-4fa0fb06] {\n  width: 48px;\n  height: 48px;\n  background-repeat: no-repeat;\n}\n.book-interpreter-icon[data-v-4fa0fb06] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.order-translation-icon[data-v-4fa0fb06] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.book-sing-lang-translator[data-v-4fa0fb06] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.other-service[data-v-4fa0fb06] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n.assignment-button__text[data-v-4fa0fb06] {\n  padding: 0 16px 0 16px;\n  font-weight: 400;\n  font-size: 14px;\n}\n.assignment-button__arrow-icon[data-v-4fa0fb06] {\n  width: 16px;\n  height: 16px;\n  margin-left: auto;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
